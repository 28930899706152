import ApiRequest from '@/core/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import UserModel from '@/core/models/User'
import RoomModel from '@/core/models/Room'

/**
 * Root Vuex state.
 */
export default class RootState {
  /**
   * Is search results loading state.
   */
  public isLoading: boolean = false

  /**
   * Is user dialog shown state.
   */
  public isUserDialogShown: boolean = false

  /**
   * Is history dialog shown state.
   */
  public isHistoryDialogShown: boolean = false

  /**
   * State of application roomsList.
   */
  public roomsList: RoomModel[] = []

  /**
   * State of application usersList.
   */
  public usersList: UserModel[] = []

  /**
   * State of application accessModel.
   */
  public accessModel: AccessModel | null

  /**
   * State of application apiRequest.
   */
  public apiRequest: ApiRequest | null

  /**
   * Creates initial root store state.
   */
  constructor () {
    this.roomsList = []
    this.usersList = []
    this.accessModel = null
    this.apiRequest = null
    this.isUserDialogShown = false
    this.isHistoryDialogShown = false
  }
}
