export const TABLES_ALL: string = 'Весь зал'

/**
 * Room with details.
 */
export default class Room {
    /**
     * Room id.
     */
    public id: string = '';

    /**
     * Room name.
     */
    public name: string = '';

    /**
     * Room color.
     */
    public color: string = '';

    /**
     * Room tables name.
     */
    public tables_name: string|null = null;

    /**
     * Room tables count.
     */
    public tables_count: number = 1;

    /**
     * Room created date.
     */
    public created_at: any = 'null';
}
