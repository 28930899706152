import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'
import moment from 'moment'

import ApiRequest from '@/core/ApiRequest'
import EventModel, { EventStatus } from '@/core/models/Event'
import RoomModel, { TABLES_ALL } from '@/core/models/Room'
import { ADD_EVENT_ITEM, FETCH_ROOMS_LIST } from '@/store/root/actions'
import { GET_API_REQUEST, GET_ROOMS_LIST, IS_HISTORY_DIALOG_SHOWN } from '@/store/root/getters'
import { SET_HISTORY_DIALOG_SHOWN } from '@/store/root/mutations'

import EventHistory from '@/components/shared/event-history/EventHistory.vue'


@Component({
  components: { EventHistory }
})
export default class Event extends Vue {
  public room: RoomModel | null = null;
  public tableNumbers: Array<string> = [];
  public dateFrom: string = '';
  public dateTo: string = '';
  public timeFrom: any = '10:00';
  public timeTo: any = '23:00';
  public clientName: any = '';
  public clientPhone: any = '';
  public clientComments: any = '';
  public status: EventStatus = EventStatus.ORDERED;
  public statusCancellationReason: any = '';
  public statusConfirmationDate: string | null = null;
  public paymentAmount: any = '';
  public paymentDate: string | null = null;
  public paymentOwner: string = '';
  public paymentType: string = '';
  public managerComments: any = '';
  public EventStatus: any = EventStatus;

  public dateFromMenu = false;
  public timeFromMenu = false;
  public dateToMenu = false;
  public timeToMenu = false;
  public statusConfirmationDateMenu = false;
  public paymentDateMenu = false;
  public errorMessage: string | null = '';
  public errorMessagePhone: string | null = '';
  public isLoading = false;
  public canEdit = false;

  public date: string = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
    .toISOString().substr(0, 10);

  /**
   * Property that indicates ID of shown event.
   */
  @Prop({ default: null })
  public id: string | null;

  /**
   * Property that indicates is edit mode enabled.
   */
  @Prop({ default: true })
  public editable: boolean;

  /**
   * Fetch rooms list.
   */
  @Action(FETCH_ROOMS_LIST)
  public fetchRoomsList:
    () => void;

  /**
   * Add event item.
   */
  @Action(ADD_EVENT_ITEM)
  public addEventItem:
    /**
     * @param event      Event to add.
     *
     * @return   Resolved promise.
     */
    (event: EventModel) => Promise<void>

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Get rooms list, based on global Vuex state.
   */
  @Getter(GET_ROOMS_LIST)
  public getRoomsList: RoomModel[];

  /**
   * Get is history dialog shown flag, based on global Vuex state.
   */
  @Getter(IS_HISTORY_DIALOG_SHOWN)
  public isHistoryDialogShown: boolean

  /**
   * Sets is history dialog shown flag state.
   */
  @Mutation(SET_HISTORY_DIALOG_SHOWN)
  public setHistoryDialogShown: (isHistoryDialogShown: boolean) => void

  /**
   * Room ID getter.
   */
  public get roomId (): string | null {
    return this.room
      ? this.room.id
      : null
  }

  /**
   * Room ID setter.
   */
  public set roomId (value: string | null) {
    this.room = this.getRoomsList.find((room) => room.id === value) || null
    this.tableNumbers = [TABLES_ALL]
  }

  /**
   * Returns list of tables for current room.
   */
  public getTablesList () {
    if (this.room === null) {
      return []
    }
    const tables: Array<string> = Array.from(
      { length: this.room.tables_count },
      (_, i) => String(i + 1))
    tables.unshift(TABLES_ALL)
    return tables
  }

  /**
   * Returns statuses list.
   */
  public getStatusesList () {
    const statuses = [
      { status: EventStatus.PREORDERED, title: 'Предзаказ' }, // TODO: move to models
      { status: EventStatus.ORDERED, title: 'Заказ' },
    ]
    if (this.id) {
      statuses.push({ status: EventStatus.CANCELED, title: 'Отмененный заказ' })
    }
    return statuses
  }

  /**
   * Allowed step for time picker.
   *
   * @param min
   */
  public timePickerAllowedStep (min: number) {
    return min % 5 === 0
  }

  public onDateFromChange (value: string) {
    this.dateFromMenu = false
    console.log(`onDateFromChange(${value})`)
    if (this.dateTo === '' || this.dateTo < this.dateFrom) {
      this.dateTo = value
    }
  }

  public onPhoneInput (formattedNumber: string, options: any) {
    const { number, valid, country } = options
    this.clientPhone = number.international
    this.errorMessagePhone = valid
      ? null
      : 'Номер указан неверно'
  }

  /**
   * Form submit handler.
   */
  public async formSubmit () {
    console.log('formSubmit')
    this.errorMessage = ''
    this.isLoading = true

    // todo: move to vue-validate rules
    if (!this.room) {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указан зал!'
      return
    }
    if (!this.clientName) {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указано имя клиента!'
      return
    }
    if (!this.clientPhone) {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указан телефон клиента!'
      return
    }
    if (!this.dateFrom || this.dateFrom === '') {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указана дата начала бронирования!'
      return
    }
    if (!this.dateTo || this.dateTo === '') {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указана дата окончания бронирования!'
      return
    }
    if (this.paymentAmount && !this.paymentDate) {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указана дата оплаты!'
      return
    }
    if (this.paymentAmount && !this.paymentOwner) {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указано кому оплачено!'
      return
    }
    if (this.paymentAmount && !this.paymentType) {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указан способ оплаты!'
      return
    }
    if (this.status === EventStatus.PREORDERED && !this.statusConfirmationDate) {
      this.isLoading = false
      this.errorMessage = 'Ошибка: не указана дата подтверждения бронирования!'
      return
    }

    const event = new EventModel()
    if (this.id) {
      event.id = this.id
    }
    event.room_id = this.room?.id
    event.table_numbers = this.tableNumbers.includes(TABLES_ALL)
      ? 'all'
      : this.tableNumbers.join(',')
    event.client_name = this.clientName
    event.client_phone = this.clientPhone
    event.client_comments = this.clientComments
    event.date_from = (new Date(this.dateFrom + 'T' + this.timeFrom)).toISOString()
    event.date_to = (new Date(this.dateTo + 'T' + this.timeTo)).toISOString()
    console.log(`this.statusConfirmationDate=${this.statusConfirmationDate}`)
    event.status_confirmation_date = this.statusConfirmationDate && this.statusConfirmationDate !== ''
      ? (new Date(this.statusConfirmationDate)).toISOString()
      : null
    event.payment_amount = this.paymentAmount
    event.payment_type = this.paymentType
    event.payment_owner = this.paymentOwner
    console.log(`this.paymentDate=${this.paymentDate}`)
    event.payment_date = this.paymentDate && this.paymentDate !== ''
      ? (new Date(this.paymentDate)).toISOString()
      : null
    event.manager_comments = this.managerComments
    event.status = this.status

    try {
      await this.addEventItem(event)
      this.isLoading = false

      if (!this.id) {
        console.log('goto home')
        this.$router.push({ name: 'home' })
      } else {
        console.log('goto self')
        this.$router.go(0)
      }
    } catch (error) {
      this.isLoading = false
      if (error.error && error.error === 'Overbooking') {
        this.errorMessage = 'Overbooking: Выбранные столы уже забронированы. Выберите другие столы или измените дату.'
      }
    }
  }

  /**
   * Fetch current event and fill component inputs.
   */
  public async fetchCurrentEvent () {
    const response = await this.getApiRequest.send(
      'GET',
      'events/' + encodeURIComponent(this.id!),
    )
    if (!response || !response.data) { return }

    const event = response.data
    console.log(event)
    this.room = this.getRoomsList.find((room) => room.id === event.room_id) || null
    const tableItems = event.table_numbers.split(',')
    this.tableNumbers = tableItems.includes('all') ? [TABLES_ALL] : tableItems
    this.clientName = event.client_name
    this.clientPhone = event.client_phone.replace(/^\+/g, '')
    this.clientComments = event.client_comments

    const dateFrom = new Date(event.date_from)
    const dateTo = new Date(event.date_to)
    this.dateFrom = moment(dateFrom.toISOString()).format('YYYY-MM-DD')
    this.dateTo = moment(dateTo.toISOString()).format('YYYY-MM-DD')
    this.timeFrom = dateFrom.toLocaleTimeString()
    this.timeTo = dateTo.toLocaleTimeString()

    this.status = event.status
    const statusConfirmationDate = new Date(event.status_confirmation_date)
    this.statusConfirmationDate = !isNaN(Date.parse(event.status_confirmation_date))
      ? moment(statusConfirmationDate.toISOString()).format('YYYY-MM-DD')
      : null

    this.paymentAmount = event.payment_amount
    this.paymentType = event.payment_type
    this.paymentOwner = event.payment_owner
    const paymentDate = (new Date(event.payment_date)) || null
    this.paymentDate = !isNaN(Date.parse(event.payment_date))
      ? moment(paymentDate.toISOString()).format('YYYY-MM-DD')
      : null
    this.managerComments = event.manager_comments
  }

  /**
   * Show event history.
   */
  public showHistory () {
    this.setHistoryDialogShown(true)
  }

  @Watch('tableNumbers')
  public onShownChanged (value: Array<string>, oldvalue: Array<string>) {
    if (value.includes(TABLES_ALL) && value.length > 1) {
      if (!oldvalue.includes(TABLES_ALL)) {
        this.tableNumbers = [TABLES_ALL]
      } else {
        this.tableNumbers = this.tableNumbers.filter(tableName => tableName !== TABLES_ALL)
      }
    }
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    this.canEdit = this.editable
    await this.fetchRoomsList()
    if (this.id) {
      await this.fetchCurrentEvent()
    }
    if (this.canEdit && !this.id && this.$route.query.date) {
      const date = new Date(this.$route.query.date as string)
      this.dateFrom = moment(date.toISOString()).format('YYYY-MM-DD')
      this.dateTo = moment(date.toISOString()).format('YYYY-MM-DD')
    }
  }
}
