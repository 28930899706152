import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import moment from 'moment'

import ApiRequest from '@/core/ApiRequest'
import { EventStatus } from '@/core/models/Event'
import { TABLES_ALL } from '@/core/models/Room'
import { GET_API_REQUEST } from '@/store/root/getters'


@Component
export default class DayEvents extends Vue {
  public EventStatus: any = EventStatus

  public events: Array<any> = []

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  public getFormattedDate (isoDate: string) {
    const date = new Date(isoDate)
    const thisDayDate = new Date(this.$route.params.date)
    return date.toLocaleDateString() !== thisDayDate.toLocaleDateString()
      ? date.toLocaleString()
      : date.toLocaleTimeString()
  }

  public getDateTitle (): string {
    const routeDate = new Date(this.$route.params.date)
    return moment(routeDate.toISOString()).format('LL')
  }

  public getTablesNumbers (event: any): string {
    if (event.table_numbers === 'all') {
      return TABLES_ALL
    }
    return event.table_numbers
  }

  public gotoEvent (eventId: string) {
    this.$router.push({ name: 'showEvent', params: { id: eventId } })
  }

  public addNewEvent () {
    const thisDayDate = new Date(this.$route.params.date)
    this.$router.push({
      name: 'addEvent',
      query: { date: moment(thisDayDate.toISOString()).format('YYYY-MM-DD') }
    })
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    const response = await this.getApiRequest.send(
      'GET',
      'events?date_from=' +
      encodeURIComponent((new Date(`${this.$route.params.date}T00:00:00`)).toISOString()) +
      '&date_to=' +
      encodeURIComponent((new Date(`${this.$route.params.date}T23:59:59`)).toISOString()),
    )
    if (!response || !response.data) {
      return []
    }
    this.events = response.data
    console.log(response.data)
  }
}
