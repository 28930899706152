import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'

import ApiRequest from '@/core/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import {
  SET_API_REQUEST,
  SET_ACCESS_MODEL,
  setAccessModel
} from '@/store/root/mutations'
import { GET_ACCESS_MODEL, IS_LOADING } from '@/store/root/getters'


@Component
export default class App extends Vue {
  public drawer = false
  public navigationModel = 'calendar'

  /**
   * Get access model, based on global Vuex state.
   */
  @Getter(GET_ACCESS_MODEL)
  public getAccessModel: AccessModel | null

  /**
   * Get is loading value, based on global Vuex state.
   */
  @Getter(IS_LOADING)
  public isLoading: boolean

  /**
   * Sets apiRequest instance.
   */
  @Mutation(SET_API_REQUEST)
  public setApiRequest: (instance: ApiRequest) => void

  /**
   * Sets accessModel.
   */
  @Mutation(SET_ACCESS_MODEL)
  public setAccessModel: (model: AccessModel | null) => void

  /**
   * Log out from app.
   */
  public logout () {
    this.setAccessModel(null)
    this.$router.push({ name: 'login' })
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    this.setApiRequest(new ApiRequest(this.$store))
  }
}
