import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import store from '@/store'
import { GET_ACCESS_MODEL } from '@/store/root/getters'

import Home from '@/components/views/home/Home.vue'
import AddEvent from '@/components/views/add-event/AddEvent.vue'
import DayEvents from '@/components/views/day-events/DayEvents.vue'
import Login from '@/components/views/login/Login.vue'
import ShowEvent from '@/components/views/show-event/ShowEvent.vue'
import Users from '@/components/views/users/Users.vue'
import ForgetPassword from '@/components/views/forget-password/ForgetPassword.vue'
import SetPassword from '@/components/views/set-password/SetPassword.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/add-event',
    name: 'addEvent',
    component: AddEvent
  },
  {
    path: '/day-events/:date',
    name: 'dayEvents',
    component: DayEvents
  },
  {
    path: '/forget-password',
    name: 'forgetPassword',
    component: ForgetPassword
  },
  {
    path: '/set-password',
    name: 'setPassword',
    component: SetPassword
  },
  {
    path: '/events/:id',
    name: 'showEvent',
    component: ShowEvent
    // component: () => import(/* webpackChunkName: "about" */ '@/components/views/about/About.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const skipAuthPages = ['login', 'forgetPassword', 'setPassword']
  if (!skipAuthPages.includes(to.name || 'home') && !store.getters[GET_ACCESS_MODEL]) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
