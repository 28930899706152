/**
 * Available event statuses.
 */
export enum EventStatus {
  PREORDERED = 'preordered',
  ORDERED = 'ordered',
  CANCELED = 'canceled',
}

/**
 * Event with details.
 */
export default class Event {
  /**
   * Unique ID.
   */
  public uq_id: string;

  /**
   * Event ID.
   */
  public id: string;

  /**
   * Revision ID.
   */
  public revision_id: string = '';

  /**
   * Room ID.
   */
  public room_id: string = '';

  /**
   * Table number.
   */
  public table_numbers: string = 'all';

  /**
   * Date from, as ISO string.
   */
  public date_from: string | null = null;

  /**
   * Date to, as ISO string.
   */
  public date_to: string | null = null;

  /**
   * Client name.
   */
  public client_name: string = '';

  /**
   * Client phone.
   */
  public client_phone: string = '';

  /**
   * Client`s comments.
   */
  public client_comments: string | null = null;

  /**
   * Status of event.
   */
  public status: string = EventStatus.ORDERED;

  /**
   * Status cancellation reason.
   */
  public status_cancellation_reason: string | null = null;

  /**
   * Status confirmation date.
   */
  public status_confirmation_date: any | null = null; // TODO: date type

  /**
   * Payment amount.
   */
  public payment_amount: number | null = null;

  /**
   * Payment date ID, as ISO string.
   */
  public payment_date: string | null = null;

  /**
   * Payment owner.
   */
  public payment_owner: string = '';

  /**
   * Payment type.
   */
  public payment_type: string = '';

  /**
   * Manager`s comments.
   */
  public manager_comments: string | null = null;

  /**
   * Room created date.
   */
  public created_at: any = 'null';


  public fillFromFields (fields: any) {
    // TODO
  }
}
