import { Store } from 'vuex'

import ResponseModel from '@/core/models/ResponseModel'
import AccessModel from '@/core/models/AccessModel'
import * as getters from '@/store/root/getters'
import * as mutations from '@/store/root/mutations'


// const apiUrl = 'http://localhost:3099/v1/'
const apiUrl = 'http://fidan.verygame.ru:3099/v1/'

/**
 * Helper class for sending http requests.
 */
export default class ApiRequest {
  /**
   * Vuex root store.
   */
  private store: Store<{}>;

  /**
   * Creates Startup instance.
   *
   * @param store   Vuex root store.
   */
  public constructor (store: Store<{}>) {
    this.store = store
  }

  /**
   * Sends request to API.
   *
   * @param method             Request method.
   * @param endpoint           Api endpoint url.
   * @param body               Json-type request body.
   * @param skipRedirectOnFail Skip redirect on fail auth.
   * @param authRequired       Is auth required for request.
   *
   * @return   Resolved promise with server response.
   */
  public async send (
    method: string,
    endpoint: string,
    body?: string,
    skipRedirectOnFail: boolean = false,
    authRequired: boolean = true
  ): Promise<ResponseModel> {
    let response: ResponseModel
    return new Promise((resolve, reject) => {
      const accessModel = this.store.getters[`${getters.GET_ACCESS_MODEL}`]

      const http = new XMLHttpRequest()
      http.open(method, apiUrl + endpoint)
      http.setRequestHeader('Content-type', 'application/json')
      if (accessModel) {
        http.setRequestHeader('authorization', 'Bearer ' + accessModel.accessToken)
      } else if (authRequired) {
        // TODO: reject(new Error(http.response)) or redirect?
      }
      http.addEventListener('readystatechange', () => {
        if (http.readyState === 4) {
          if ([200, 201].includes(http.status)) {
            const headers = http.getAllResponseHeaders()
            try {
              const data = JSON.parse(http.response)
              const headers = http.getAllResponseHeaders()
              resolve(new ResponseModel(data, headers))
            } catch (error) {
              reject(error)
            }
          } else if (http.status === 401) {
            this.store.commit(`${mutations.SET_ACCESS_MODEL}`, null)
            if (!skipRedirectOnFail) {
              console.log('REDIRECT!!! to login') // TODO
              window.location.href = '/login'
            } else {
              reject(new Error(http.response))
            }
          } else {
            if (http.response === 'Bad Request') {
              reject(new Error(http.response))
            } else {
              try {
                reject(JSON.parse(http.response))
              } catch (error) {
                reject(error)
              }
            }
          }
        }
      }, false)
      http.send(body === undefined ? '' : body)
    })
  }

  // /**
  //  * Returns true, if access token is valid.
  //  */
  // public isValidToken (): boolean {
  //   const accessModel = this.store.getters[`${getters.GET_ACCESS_MODEL}`]
  //   if (accessModel === null) {
  //     return false
  //   }
  //
  //   const currentTime = Date.now()
  //   const expiredTime = (currentTime - accessModel.renewTime) / 1000
  //
  //   if (expiredTime < accessModel.expireIn) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  //
  // /**
  //  * Returns true if auth token in short will not valid and must be renewed.
  //  */
  // public isNeedRefreshToken (): boolean {
  //   const accessModel = this.store.getters[`${getters.GET_ACCESS_MODEL}`]
  //   const currentTime = Date.now()
  //   const expiredTime = (currentTime - accessModel.renewTime) / 1000
  //
  //   if (expiredTime < (accessModel.expireIn - accessModel.refreshBeforeTime)) {
  //     return false
  //   } else {
  //     return true
  //   }
  // }
}
