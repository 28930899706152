import { ActionContext, ActionTree } from 'vuex'

import ApiRequest from '@/core/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import ResponseModel from '@/core/models/ResponseModel'
import RoomModel from '@/core/models/Room'
import UserModel from '@/core/models/User'
import Event from '@/core/models/Event'
import RootState from '@/store/root/state'
import * as mutations from '@/store/root/mutations'


export const FETCH_ROOMS_LIST: string = 'fetchRoomsList'

export const FETCH_USERS_LIST: string = 'fetchUsersList'

export const ADD_EVENT_ITEM: string = 'addEventItem'

export const ADD_USER_ITEM: string = 'addUserItem'

export const UPDATE_USER_ITEM: string = 'updateUserItem'

export const PASSWORD_CHANGE_REQUEST: string = 'passwordChangeRequest'

export const PASSWORD_SET_FOR_REQUEST: string = 'passwordSetForRequest'

export const UNLOCK_USER: string = 'unlockUser'

export const LOCK_USER: string = 'lockUser'

export const LOG_IN_USER: string = 'logInUser'

/**
 * Fetch rooms list.
 *
 * @param store   Root Vuex store.
 *
 * @returns       Resolved promise.
 */
export function fetchRoomsList (
  store: ActionContext<RootState, any>,
) {
  return store.getters.getApiRequest.send(
    'GET',
    'rooms',
  ).then((response: ResponseModel) => {
    store.state.roomsList = response.data
  })
}

/**
 * Fetch users list.
 *
 * @param store   Root Vuex store.
 *
 * @returns       Resolved promise.
 */
export function fetchUsersList (
  store: ActionContext<RootState, any>,
) {
  return store.getters.getApiRequest.send(
    'GET',
    'users',
  ).then((response: ResponseModel) => {
    store.state.usersList = response.data // todo: destruct data and create objects
  })
}

/**
 * Add event item.
 *
 * @param store   Root Vuex store.
 * @param event   Event to be added.
 *
 * @returns       Resolved promise.
 */
export function addEventItem (
  store: ActionContext<RootState, any>,
  event: Event,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'events',
    JSON.stringify({ event }),
  )
}

/**
 * Add user item.
 *
 * @param store   Root Vuex store.
 * @param user    User to be added.
 *
 * @returns       Resolved promise.
 */
export function addUserItem (
  store: ActionContext<RootState, any>,
  user: UserModel,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'users',
    JSON.stringify({ user }),
  )
}

/**
 * Update user item.
 *
 * @param store   Root Vuex store.
 * @param user    User to be updated.
 *
 * @returns       Resolved promise.
 */
export function updateUserItem (
  store: ActionContext<RootState, any>,
  user: UserModel,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'users/' + user.id,
    JSON.stringify({ user }),
  )
}

/**
 * Create password change request.
 *
 * @param store   Root Vuex store.
 * @param login   Login of user.
 *
 * @returns       Resolved promise.
 */
export function passwordChangeRequest (
  store: ActionContext<RootState, any>,
  login: string,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'users/password-change-requests',
    JSON.stringify({ login }),
    false,
    false
  )
}

/**
 * Set password by request.
 *
 * @param store     Root Vuex store.
 * @param payload   Token and new password.
 *
 * @returns       Resolved promise.
 */
export function passwordSetForRequest (
  store: ActionContext<RootState, any>,
  payload: { token: string, password: string },
) {
  const { token, password } = payload
  return store.getters.getApiRequest.send(
    'POST',
    'users/password-change-requests/' + token,
    JSON.stringify({ password }),
    false,
    false
  )
}

/**
 * Lock user.
 *
 * @param store   Root Vuex store.
 * @param user    User to be updated.
 *
 * @returns       Resolved promise.
 */
export function lockUser (
  store: ActionContext<RootState, any>,
  user: UserModel,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'users/' + user.id + '/status',
    JSON.stringify({ status: 'inactive' }),
  )
}

/**
 * Unlock user.
 *
 * @param store   Root Vuex store.
 * @param user    User to be updated.
 *
 * @returns       Resolved promise.
 */
export function unlockUser (
  store: ActionContext<RootState, any>,
  user: UserModel,
) {
  return store.getters.getApiRequest.send(
    'POST',
    'users/' + user.id + '/status',
    JSON.stringify({ status: 'active' }),
  )
}

/**
 * Log in user.
 *
 * @param store     Root Vuex store.
 * @param payload   User`s login and password.
 *
 * @returns         Resolved promise.
 */
export function logInUser (
  store: ActionContext<RootState, any>,
  payload: { login: string, password: string },
) {
  const { login, password } = payload
  return store.getters.getApiRequest.send(
    'POST',
    'auth',
    JSON.stringify({ login, password }),
    true
  ).then((response: ResponseModel) => {
    const { access_token, refresh_token } = response.data
    const accessModel = new AccessModel(access_token, refresh_token)
    store.commit(mutations.SET_ACCESS_MODEL, accessModel)

    return store.getters.getApiRequest.send(
      'GET',
      'auth',
    )
  }).then((response: ResponseModel) => {
    const user = response.data
    const accessModel = store.getters.getAccessModel
    accessModel.issuedAt = user.iat
    accessModel.expiredAt = user.exp

    accessModel.user = new UserModel()
    accessModel.user.id = user.id
    accessModel.user.username = user.username
    accessModel.user.fullname = user.fullname
    accessModel.user.email = user.email
    accessModel.user.role = user.role
    accessModel.user.created_at = user.created_at

    store.commit(mutations.SET_ACCESS_MODEL, accessModel)
  })
}

export default {
  addEventItem,
  addUserItem,
  fetchRoomsList,
  fetchUsersList,
  logInUser,
  updateUserItem,
  passwordChangeRequest,
  passwordSetForRequest,
  unlockUser,
  lockUser
} as ActionTree<RootState, any>
