import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, Getter, Mutation } from 'vuex-class'

import ApiRequest from '@/core/ApiRequest'
import { EventStatus } from '@/core/models/Event'
import RoomModel, { TABLES_ALL } from '@/core/models/Room'
import { GET_API_REQUEST, GET_ROOMS_LIST, IS_HISTORY_DIALOG_SHOWN } from '@/store/root/getters'
import { SET_HISTORY_DIALOG_SHOWN } from '@/store/root/mutations'


@Component
export default class Event extends Vue {
  public EventStatus: any = EventStatus;

  public history: Array<any> = []

  /**
   * Property that indicates ID of shown event.
   */
  @Prop({ default: null })
  public id: string | null;

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  /**
   * Get rooms list, based on global Vuex state.
   */
  @Getter(GET_ROOMS_LIST)
  public getRoomsList: RoomModel[];

  /**
   * Get is history dialog shown flag, based on global Vuex state.
   */
  @Getter(IS_HISTORY_DIALOG_SHOWN)
  public isHistoryDialogShown: boolean

  /**
   * Sets is history dialog shown flag state.
   */
  @Mutation(SET_HISTORY_DIALOG_SHOWN)
  public setHistoryDialogShown: (isHistoryDialogShown: boolean) => void

  /**
   * Returns statuses list.
   */
  public getStatusesNames (): {[key: string]: any} {
    const names: any = {}
    names[EventStatus.PREORDERED] = 'Предзаказ'
    names[EventStatus.ORDERED] = 'Заказ'
    names[EventStatus.CANCELED] = 'Отмененный заказ'
    return names
  }

  /**
   * Fetch history of current event.
   */
  public async fetchEventHistory () {
    if (!this.id) { return }

    const response = await this.getApiRequest.send(
      'GET',
      'events/' + encodeURIComponent(this.id) + '/history',
    )
    if (!response || !response.data) { return }

    this.history = response.data
  }

  /**
   * Get formatted diff value depends on old and new values.
   *
   * @param diff        Diff item.
   * @param isNewValue  If true then indicate that formatted new value.
   *                    Otherwise formatted old value.
   */
  public getFormattedValue (
    diff: {field: string, old_value: string | null, new_value: string | null},
    isNewValue: boolean
  ): string {
    const value = isNewValue ? diff.new_value : diff.old_value
    if (!value || value === '') {
      return 'пусто'
    }
    if (diff.field === 'table_numbers' && value === 'all') {
      return TABLES_ALL
    }
    if (diff.field === 'status') {
      if (value === EventStatus.ORDERED) return 'Заказан'
      if (value === EventStatus.PREORDERED) return 'Предзаказ'
      if (value === EventStatus.CANCELED) return 'Отменен'
    }
    const dateFields = ['date_from', 'date_to', 'status_confirmation_date', 'payment_date']
    if (dateFields.includes(diff.field)) {
      return this.formatDate(value, 'пусто')!
    }
    return value
  }

  /**
   * Get icon color for diffs block.
   *
   * @param diffs  Diffs items.
   */
  public getColor (
    diffs: {revision_id: number, changed_at: string, diffs: any}
  ): string {
    switch (diffs.revision_id % 5) {
      case 1: return 'pink'
      case 2: return 'teal lighten-3'
      case 3: return 'indigo'
      case 4: return 'green lighten-1'
      default: return 'red lighten-2'
    }
  }

  /**
   * Format date to local format.
   */
  public formatDate (date: string, nullValue: string | null): string | null {
    return isNaN(Date.parse(date))
      ? nullValue
      : (new Date(date)).toLocaleDateString() + ' ' + (new Date(date)).toLocaleTimeString()
  }

  /**
   * Get formatted field.
   *
   * @param field  Field name.
   */
  public getFormattedField (field: string): string {
    const fieldNames: any = {
      room_name: 'Зал',
      tables_name: 'Тип объекта',
      table_numbers: 'Номер стола',
      date_from: 'Дата начала',
      date_to: 'Дата окончания',
      client_name: 'Имя клиента',
      client_phone: 'Телефон клиента',
      client_comments: 'Комментарии клиента',
      status: 'Статус',
      status_cancellation_reason: 'Причина отмены заказа',
      status_confirmation_date: 'Дата подтверждения заказа',
      payment_amount: 'Сумма оплаты',
      payment_date: 'Дата оплаты',
      payment_owner: 'Кому оплачено',
      payment_type: 'Способ оплаты',
      manager_comments: 'Комментарии менеджера'
    }
    return fieldNames[field] || ''
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    await this.fetchEventHistory()
  }
}
