import { GetterTree } from 'vuex'

import ApiRequest from '@/core/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import RoomModel from '@/core/models/Room'
import UserModel from '@/core/models/User'
import RootState from '@/store/root/state'


export const IS_LOADING: string = 'isLoading'

export const IS_USER_DIALOG_SHOWN: string = 'isUserDialogShown'

export const IS_HISTORY_DIALOG_SHOWN: string = 'isHistoryDialogShown'

export const GET_ROOMS_LIST: string = 'getRoomsList'

export const GET_USERS_LIST: string = 'getUsersList'

export const GET_ACCESS_MODEL: string = 'getAccessModel'

export const GET_API_REQUEST: string = 'getApiRequest'

/**
 * Return is loading flag from store.
 *
 * @param state     Root Vuex state.
 *
 * @returns     Is loading flag.
 */
export function isLoading (state: RootState): boolean {
  return state.isLoading
}

/**
 * Return is user dialog shown flag from store.
 *
 * @param state     Root Vuex state.
 *
 * @returns     Is loading flag.
 */
export function isUserDialogShown (state: RootState): boolean {
  return state.isUserDialogShown
}

/**
 * Return is history dialog shown flag from store.
 *
 * @param state     Root Vuex state.
 *
 * @returns     Is loading flag.
 */
export function isHistoryDialogShown (state: RootState): boolean {
  return state.isHistoryDialogShown
}

/**
 * Returns roomsList state.
 *
 * @param state   Models Vuex state.
 */
export function getRoomsList (state: RootState): RoomModel[] | null {
  return state.roomsList
}

/**
 * Returns roomsList state.
 *
 * @param state   Models Vuex state.
 */
export function getUsersList (state: RootState): UserModel[] {
  return state.usersList
}

/**
 * Returns accessModel state.
 *
 * @param state   Models Vuex state.
 */
export function getAccessModel (state: RootState): AccessModel | null {
  return state.accessModel
}


/**
 * Returns apiRequest object.
 *
 * @param state   Models Vuex state.
 */
export function getApiRequest (state: RootState): ApiRequest {
  if (state.apiRequest === null) {
    throw new Error('ApiRequest not found')
  }
  return state.apiRequest
}

export default {
  isLoading,
  getAccessModel,
  getApiRequest,
  getRoomsList,
  getUsersList,
  isUserDialogShown,
  isHistoryDialogShown,
} as GetterTree<RootState, any>
