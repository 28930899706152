import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'

import ApiRequest from '@/core/ApiRequest'
import AccessModel from '@/core/models/AccessModel'
import { TABLES_ALL } from '@/core/models/Room'
import { GET_ACCESS_MODEL, GET_API_REQUEST } from '@/store/root/getters'

import Calendar from '@/components/shared/calendar/Calendar.vue'


@Component({
  components: { Calendar }
})
export default class Home extends Vue {
  public outdatedEvents: Array<any> = []

  /**
   * Get access model, based on global Vuex state.
   */
  @Getter(GET_ACCESS_MODEL)
  public getAccessModel: AccessModel | null

  /**
   * Get api request object, based on global Vuex state.
   */
  @Getter(GET_API_REQUEST)
  public getApiRequest: ApiRequest

  public getTablesNumbers (event: any): string {
    if (event.table_numbers === 'all') {
      return TABLES_ALL
    }
    return event.table_numbers
  }

  public getFormattedDate (isoDate: string) { // TODO: move to helpers
    const date = new Date(isoDate)
    const thisDayDate = new Date(this.$route.params.date)
    return date.toLocaleDateString() !== thisDayDate.toLocaleDateString()
      ? date.toLocaleString()
      : date.toLocaleTimeString()
  }

  public getEventTitle (event: any): string {
    let title = event.room_name
    if (event.tables_name) {
      title += ' (' + event.tables_name + ': ' + this.getTablesNumbers(event) + ')'
    }
    title += ', c ' + this.getFormattedDate(event.date_from) + ' по ' + this.getFormattedDate(event.date_to)
    return title
  }

  public gotoEvent (eventId: string) {
    this.$router.push({ name: 'showEvent', params: { id: eventId } })
  }

  /**
   * Created lifecycle hook. Fires when component instance is created.
   */
  public async created () {
    this.outdatedEvents = []
    if (!this.getAccessModel) {
      return
    }

    const response = await this.getApiRequest.send(
      'GET',
      'events/outdated'
    )
    if (response && response.data) {
      this.outdatedEvents = response.data
      console.log('this.outdatedEvents=', this.outdatedEvents) // TODO: delete
    }
  }
}
